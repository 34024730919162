<template>
  <b-card :title="title">
    <validation-observer ref="simpleRules">
      <b-form>
        <div class="demo-spacing-0">
          <b-alert
            v-if="errors"
            show
            variant="danger"
          >
            <h4 class="alert-heading">
              Errors
            </h4>
            <div class="alert-body">
              <div
                v-for="(v, k) in errors"
                :key="k"
              >
                <p
                  v-for="error in v"
                  :key="error"
                  class="text-sm"
                >
                  {{ error }}
                </p>
              </div>
            </div>
          </b-alert>
        </div>
        <b-row>
          <b-col md="3" />
          <b-col md="6">
            <b-form-group
              label="AWB NO."
            >
              <validation-provider
                #default="{ errors }"
                name="awb no"
                rules=""
              >
                <b-form-input
                  v-model="form.awb"
                  :state="errors.length > 0 ? false:null"
                  placeholder="AWB No."
                  disabled="disabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" />
          <b-col md="6">
            <b-form-group label="Consignee">
              <validation-provider
                #default="{ errors }"
                name="consignee (receiver)"
                rules=""
              >
                <b-input-group>
                  <v-select
                    id="consignee_id"
                    v-model="form.consignee_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :filterable="false"
                    :options="clients"
                    label="name"
                    placeholder="Search by client no, name, contact name, phone or mobile..."
                    style="width: 70%"
                    disabled="disabled"
                    @search="searchClient"
                    @option:selected="consignee_selected"
                  >
                    <template slot="no-options">
                      type to search consignee..
                    </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      <div class="d-center">
                        {{ option.client_no }} - {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <b-input-group-append>
                    <b-button
                      v-b-modal.modal-select2
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                    >
                      Consignee info
                    </b-button>
                    <!--                                        <b-dropdown
                                                                text="Action"
                                                                variant="outline-primary"
                                                                right
                                                            >
                                                                <b-dropdown-item>Action</b-dropdown-item>
                                                                <b-dropdown-item>Another Action</b-dropdown-item>
                                                                <b-dropdown-item>Something else here</b-dropdown-item>
                                                                <b-dropdown-divider/>
                                                                <b-dropdown-item>Separated link</b-dropdown-item>
                                                            </b-dropdown>-->
                  </b-input-group-append>

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Type Of Service">
              <validation-provider
                #default="{ errors }"
                name="type of service"
                rules="required"
              >
                <v-select
                  id="service_type_id"
                  v-model="form.service_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :filterable="false"
                  :options="serviceTypes"
                  label="title"
                  placeholder="Type Of Service"
                  :disabled="$route.params.id ? 'disabled': false"
                  @search="searchServiceType"
                >
                  <template slot="no-options">
                    type to search type of service..
                  </template>
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <div class="d-center">
                      {{ option.title }}
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Ref No."
            >
              <validation-provider
                #default="{ errors }"
                name="Ref No."
                rules=""
              >
                <b-form-input
                  v-model="form.ref_no"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Ref No."
                  :disabled="$route.params.id ? 'disabled': false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="COD Amount"
            >
              <validation-provider
                #default="{ errors }"
                name="COD Amount"
                rules=""
              >
                <b-form-input
                  v-model="form.cod_amount"
                  :state="errors.length > 0 ? false:null"
                  placeholder="COD Amount"
                  :disabled="$route.params.id ? 'disabled': false"
                  @change="checkForCodAmount"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Fees"
            >
              <validation-provider
                #default="{ errors }"
                name="Fees"
                rules=""
              >
                <b-form-input
                  v-model="form.fees"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Fees"
                  :disabled="$route.params.id ? 'disabled': false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Charge Type">
              <validation-provider
                #default="{ errors }"
                name="charge Type"
                rules="required"
              >
                <v-select
                  id="charge_type"
                  v-model="form.charge_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :disabled="form.cod_amount !== '' || $route.params.id ? 'disabled': false"
                  :options="chargeTypes"
                  :reduce="item => item.id"
                  label="name"
                  placeholder="Choose charge type"
                >
                  <template slot="no-options">
                    type to search charge no..
                  </template>
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <div class="d-center">
                      {{ option.client_no }} - {{ option.name }}
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-show="form.charge_type === 'other'"
            md="6"
          >
            <b-form-group label="Charge On">
              <validation-provider
                #default="{ errors }"
                name="charge on"
                rules=""
              >
                <v-select
                  id="charge_on"
                  v-model="form.charge_on"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :filterable="false"
                  :options="clients"
                  label="name"
                  placeholder="Search by client no, name, contact name, phone or mobile..."
                  :disabled="$route.params.id ? 'disabled': false"
                  @search="searchClient"
                >
                  <template slot="no-options">
                    type to search charge no..
                  </template>
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <div class="d-center">
                      {{ option.client_no }} - {{ option.name }}
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Remarks"
            >
              <validation-provider
                #default="{ errors }"
                name="Remarks"
                rules=""
              >
                <b-form-input
                  v-model="form.remarks"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Remarks"
                  :disabled="$route.params.id ? 'disabled': false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Total Amount"
            >
              <b-form-input
                :value="(form.cod_amount ? parseFloat(form.cod_amount) : 0 ) + (form.fees ? parseFloat(form.fees) : 0)"
                placeholder="Total Amount"
                readonly="readonly"
              />
            </b-form-group>
          </b-col>
          <!--          <b-col md="6">
            <b-form-group
              label="Pieces"
            >
              <b-form-input
                v-model="form.pieces"
                number="number"
                placeholder="Pieces"
                :disabled="$route.params.id ? 'disabled': false"
              />
            </b-form-group>
          </b-col>-->
          <b-col md="12">
            <b-card title="Shipment Items">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="$route.params.id ? 'disabled': false"
                @click="updateShipmentPieces"
              >
                <feather-icon
                  class="mr-25"
                  icon="PlusIcon"
                />
                <span>Add more Pieces</span>
              </b-button>
              <b-form
                v-for="(pieceItem, pieceIndex) in form.pieces"
                :key="pieceIndex"
                ref="form"
                :style="{height: trHeight}"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <h3 style="text-align: center">
                  Piece: #{{ pieceIndex + 1 }}
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    class="mt-0 mt-md-0"
                    variant="outline-danger"
                    :disabled="$route.params.id ? 'disabled': false"
                    @click="removePieceItem(pieceIndex)"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="XIcon"
                    />
                    <span>Delete</span>
                  </b-button>
                </h3>
                <b-row
                  v-for="(item, index) in pieceItem.items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >

                  <!-- Item Name -->
                  <b-col md="2">
                    <b-form-group
                      label="Description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="item.description_of_goods"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Description of goods"
                          rows="3"
                          :disabled="$route.params.id ? 'disabled': false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group
                      label="Country Of Origin"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="country_of_origin"
                        rules=""
                      >
                        <b-form-input
                          v-model="item['country_of_origin']"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Country Of Origin"
                          type="text"
                          :disabled="$route.params.id ? 'disabled': false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group
                      label="weight"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="weight"
                        rules="required"
                      >
                        <b-form-input
                          v-model="item['weight']"
                          :state="errors.length > 0 ? false:null"
                          placeholder="weight"
                          type="text"
                          :disabled="$route.params.id ? 'disabled': false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-form-group
                      label="price"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="price"
                        rules=""
                      >
                        <b-form-input
                          v-model="item['price']"
                          :state="errors.length > 0 ? false:null"
                          placeholder="price"
                          type="text"
                          :disabled="$route.params.id ? 'disabled': false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    class="mb-50"
                    lg="2"
                    md="3"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      class="mt-0 mt-md-2"
                      variant="outline-danger"
                      :disabled="$route.params.id ? 'disabled': false"
                      @click="removeItem(pieceIndex, index)"
                    >
                      <feather-icon
                        class="mr-25"
                        icon="XIcon"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                </b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="$route.params.id ? 'disabled': false"
                  @click="repeateAgain(pieceIndex)"
                >
                  <feather-icon
                    class="mr-25"
                    icon="PlusIcon"
                  />
                  <span>Add more items</span>
                </b-button>
                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col md="5" />
                </b-row>
              </b-form>
            </b-card>
          </b-col>

          <!-- submit button -->
          <b-col md="5" />
          <b-col md="6">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              type="button"
              variant="warning"
              @click.prevent="cancel"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-select2"
      cancel-variant="outline-secondary"
      ok-title="Save"
      title="Add Consignee"
    >
      <b-form>
        <b-form-group
          label="Consignee Name"
          label-for="consignee_name"
        >
          <b-form-input
            id="consignee_name"
            v-model="form.consignee_name"
            placeholder="Consignee name"
          />
        </b-form-group>
        <b-form-group
          label="Atten To"
          label-for="atten_to"
        >
          <b-form-input
            id="atten_to"
            v-model="form.atten_to"
            placeholder="Atten To"
          />
        </b-form-group>
        <b-form-group
          label="Choose the country"
          label-for="attn_to"
        >
          <v-select
            id="country_id"
            v-model="form.country_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="countries"
            label="title"
            @option:selected="countrySelected"
          />
        </b-form-group>
        <b-form-group
          label="Choose the city"
          label-for="city_id"
        >
          <v-select
            id="city_id"
            v-model="form.city_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :disabled="!this.form.country_id"
            :options="cities"
            label="title"
          />
        </b-form-group>
        <b-form-group
          label="Area"
          label-for="area"
        >
          <b-form-input
            id="area"
            v-model="form.area"
            placeholder="Area"
          />
        </b-form-group>
        <b-form-group
          label="Address"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="form.address"
            placeholder="Address"
            type="text"
          />
        </b-form-group>
        <b-form-group
          label="Phone1"
          label-for="phone1"
        >
          <b-form-input
            id="phone1"
            v-model="form.phone1"
            placeholder="Phone1"
            type="text"
          />
        </b-form-group>
        <b-form-group
          label="Phone2"
          label-for="phone2"
        >
          <b-form-input
            id="phone2"
            v-model="form.phone2"
            placeholder="Phone2"
            type="text"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import { required } from '@core/utils/validations/validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import axios from '@/libs/axios'

export default {
  name: 'FormVue',
  components: {
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BModal,
    BFormTextarea,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      title: this.$route.params.id ? 'Edit shipment' : 'Add new shipment',
      form: {
        client_id: '',
        pickup_id: '',
        consignee_id: '',
        country_id: '',
        consignee_name: '',
        atten_to: '',
        city_id: '',
        area: '',
        address: '',
        phone1: '',
        phone2: '',
        charge_type: 'sender',
        charge_on: '',
        service_type_id: '',
        cod_amount: '',
        fees: '',
        ref_no: '',
        remarks: '',
        status_id: '',
        status: '',
        pieces: [{
          items: [{
            description_of_goods: '',
            country_of_origin: '',
            weight: '',
            price: '',
          }],
        }],
      },
      items: [],
      clients: [],
      countries: [],
      cities: [],
      serviceTypes: [],
      statuses: [],
      required,
      errors: null,
      chargeTypes: [
        { id: 'sender', name: 'sender' },
        { id: 'receiver', name: 'receiver' },
        { id: 'cache', name: 'cache' },
        { id: 'other', name: 'other' },
      ],
      url: this.$route.params.id ? `/shipments/${this.$route.params.id}` : '/shipments/',
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    if (this.$route.params.id) {
      this.getData()
      this.getStatues()
    }
    this.getCountries()
    this.getCities()
  },
  methods: {
    validationForm() {
      // this.form.client_id = this.form.client_id.id
      if (this.$route.params.id) {
        // eslint-disable-next-line no-underscore-dangle
        this.form._method = 'PUT'
      }
      this.$refs.simpleRules.validate().then(success => {
        const { form } = this
        if (success) {
          form.client_id = JSON.parse(localStorage.getItem('userData')).id
          form.pickup_id = this.$route.query.pickup_id ? this.$route.query.pickup_id : null
          form.charge_on = this.form.charge_on ? this.form.charge_on.id : null
          form.consignee_id = this.form.consignee_id ? this.form.consignee_id.id : null
          form.service_type_id = this.form.service_type_id.id
          form.country_id = (this.form.country_id ? this.form.country_id.id : '')
          form.city_id = (this.form.city_id ? this.form.city_id.id : '')
          axios.post(this.url, form).then(resp => {
            if (resp) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Data is submitted successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/shipment')
            }
          }).catch(err => {
            this.errors = err.response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    getData() {
      axios.get(`/shipments/${this.$route.params.id}`).then(resp => {
        this.form = resp.data.data
        this.form.client_id = resp.data.data.client
        this.form.consignee_id = resp.data.data.consignee
        this.form.service_type_id = resp.data.data.service_type
        this.form.country_id = resp.data.data.city.country
        this.form.city_id = resp.data.data.city
      })
    },
    getStatues() {
      axios.get('/statuses/').then(resp => {
        this.statuses = resp.data.data
      })
    },
    cancel() {
      this.$router.push('/shipment')
    },
    searchClient(search, loading) {
      if (search) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    getCountries() {
      axios.get('/countries/').then(resp => {
        this.countries = resp.data.data
      })
    },
    getCities() {
      axios.get('/cities/').then(resp => {
        this.cities = resp.data.data
      })
    },
    searchServiceType(search, loading) {
      if (search) {
        loading(true)
        this.searchServiceTypes(loading, search, this)
      }
    },
    checkForCodAmount() {
      if (this.form.cod_amount !== '') {
        this.form.charge_type = 'cache'
      } else {
        this.form.charge_type = 'sender'
      }
    },
    consignee_selected(value) {
      this.form.consignee_name = value.name
      this.form.atten_to = value.contact_name
      this.form.city_id = value.city
      this.form.address = value.addresses.length !== 0 ? value.addresses[0].address : ''
      this.form.phone1 = value.mobile
    },
    countrySelected(value) {
      axios.post('/cities/search', {
        filters: [{ field: 'country_id', operator: '=', value: value.id }],
      }).then(resp => {
        this.cities = resp.data.data
      })
    },
    repeateAgain(index) {
      this.form.pieces[index].items.push({
        description_of_goods: '',
        country_of_origin: '',
        weight: '',
        pieces: '',
        price: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(pieceIndex, index) {
      this.form.pieces[pieceIndex].items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    removePieceItem(pieceIndex) {
      this.form.pieces.splice(pieceIndex, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    changeStatusClicked(value) {
      this.form.status_id = value.id
      this.form.status = value
    },
    search: debounce((loading, search, vm) => {
      axios.post('/clients/search', {
        search: { value: search },
      }).then(res => {
        // eslint-disable-next-line no-param-reassign
        vm.clients = res.data.data
        loading(false)
      })
    }, 350),
    searchServiceTypes: debounce((loading, search, vm) => {
      axios.post('/serviceTypes/search', {
        search: { value: search },
      }).then(res => {
        // eslint-disable-next-line no-param-reassign
        vm.serviceTypes = res.data.data
        loading(false)
      })
    }, 350),
    updateShipmentPieces() {
      this.form.pieces.push({
        items: [{
          description_of_goods: '',
          country_of_origin: '',
          weight: '',
          price: '',
        }],
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
