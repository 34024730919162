<template>
  <img
    :src="appLogoImage"
    alt="logo"
    class=""
    width="150px"
  >
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  data() {
    return {
      appLogoImage: $themeConfig.app.appLogoImage,
    }
  },
}
</script>
